import React, { useEffect, useState } from 'react';
import {
  Container,
  Box,
  Button,
  VStack,
  Text,
  RadioGroup,
  Radio,
  Stack,
  Alert,
  AlertIcon,
  AlertTitle,
} from '@chakra-ui/react';
import Logo from './Logo';
import BackButton from './BackButton';
import { useAuth } from '../AuthProvider';
import {
  firestore,
  collection,
  getDocs,
  doc,
  updateDoc,
} from '../firebaseConfig';

const StudioChangePage = () => {
  const { currentUser } = useAuth();
  const [studios, setStudios] = useState([]);
  const [selectedStudioId, setSelectedStudioId] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    // スタジオ一覧を取得
    const fetchStudios = async () => {
      try {
        const studiosCollection = collection(firestore, 'studios');
        const studiosSnapshot = await getDocs(studiosCollection);
        const studiosList = studiosSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setStudios(studiosList);
      } catch (error) {
        console.error('スタジオの取得に失敗しました', error);
        setError('スタジオの取得に失敗しました。');
      }
    };
    fetchStudios();
  }, []);

  useEffect(() => {
    // デフォルトのスタジオを設定
    if (currentUser && currentUser.studioId) {
      setSelectedStudioId(currentUser.studioId);
    }
  }, [currentUser]);

  const handleStudioChange = value => {
    setSelectedStudioId(value);
  };

  const handleSave = async () => {
    try {
      const userDocRef = doc(firestore, 'users', currentUser.uid);
      await updateDoc(userDocRef, {
        studioId: selectedStudioId,
      });
      setMessage('スタジオを更新しました。');
      setError('');
    } catch (error) {
      console.error('スタジオの更新に失敗しました', error);
      setMessage('');
      setError('スタジオの更新に失敗しました。');
    }
  };

  return (
    <Container
      backgroundColor={'#ede9e5'}
      borderRadius={'10'}
      mt={4}
      pt={8}
      pb={4}
    >
      <Logo text="スタジオ変更" />
      <Box
        borderWidth="0px"
        borderRadius="xl"
        overflow="hidden"
        p={4}
        mt={4}
        backgroundColor={'#fdfdfd'}
      >
        <Box width="100%" maxW="400px" mx="auto" mt="5%">
          <VStack spacing="24px">
            <Text fontSize="14">所属するスタジオを選択してください</Text>
            {message && (
              <Alert status="success" mb="0px">
                <AlertIcon />
                <AlertTitle>{message}</AlertTitle>
              </Alert>
            )}
            {error && (
              <Alert status="error" mb="0px">
                <AlertIcon />
                <AlertTitle>{error}</AlertTitle>
              </Alert>
            )}
            {studios.length > 0 ? (
              <RadioGroup
                onChange={handleStudioChange}
                value={selectedStudioId}
              >
                <Stack direction="column">
                  {studios.map(studio => (
                    <Radio key={studio.id} value={studio.id}>
                      {studio.name}
                    </Radio>
                  ))}
                </Stack>
              </RadioGroup>
            ) : (
              <Text>スタジオ情報を読み込み中...</Text>
            )}
            <Button onClick={handleSave} colorScheme="blue" width="full" mt="4">
              適用
            </Button>
          </VStack>
        </Box>
      </Box>
      {'　'}
      <BackButton />
    </Container>
  );
};

export default StudioChangePage;
