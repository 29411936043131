import React, { useState, useEffect, useRef } from 'react';
import {
  Text,
  Container,
  Button,
  Box,
  Input,
  VStack,
  Select,
  FormLabel,
  Flex,
  useToast,
  InputGroup,
  InputRightElement,
  HStack,
  Textarea,
  Circle,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from '@chakra-ui/react';
import {
  auth,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  firestore,
  doc,
  setDoc,
  collection,
  onSnapshot,
  arrayUnion,
  signOut,
} from '../firebaseConfig';
import { useAuth } from '../AuthProvider';
import LoadingModal from '../components/LoadingModal';
import Logo from './Logo';
import { sendDiscordNotification } from '../utils/discord';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

const Trial = () => {
  const { currentUser } = useAuth();
  const [registering, setRegistering] = useState(false);
  const [studios, setStudios] = useState([]);
  const [studioId, setStudioId] = useState('');
  const [studioName, setStudioName] = useState('');
  const [email, setEmail] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [fullName, setFullName] = useState('');
  const [fullKana, setFullKana] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [displayName, setDisplayName] = useState(''); // 新しい状態を追加
  const [sex, setSex] = useState('female');
  const [path, setPath] = useState('');
  const [reason, setReason] = useState(''); // 新しい状態を追加
  const [message, setMessage] = useState('');
  const [step, setStep] = useState(1);
  const [completedSteps, setCompletedSteps] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();
  const cancelRef = useRef();

  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  const toggleConfirmPasswordVisibility = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const isValidEmail = email => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const isValidPhoneNumber = phone => /^\d+$/.test(phone);

  const normalizePhoneNumber = input =>
    input
      .replace(/[０-９]/g, s => String.fromCharCode(s.charCodeAt(0) - 0xfee0))
      .replace(/[^\d]/g, '');

  const setSelectedStudio = id => {
    setStudioId(id);
    const selectedStudio = studios.find(s => s.id === id);
    setStudioName(selectedStudio?.name);
  };

  const signUpWithEmailAndPassword = async () => {
    if (!isStepCompleted(5)) return;

    try {
      setRegistering(true);
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      await sendEmailVerification(user);
      const userRef = doc(firestore, 'users', user.uid);
      await setDoc(
        userRef,
        {
          fullName: fullName,
          fullKana: fullKana,
          displayName: displayName, // ここに表示名を追加
          phoneNumber: phoneNumber,
          email: email,
          studioId: studioId,
          belongStudioIds: { [studioId]: studioName.slice(5) },
          manageStudioIds: [],
          workingStudioIds: [],
          status: false, // 入会手続き終わったか
          trial_lesson: false, // 来店したかどうか
          trial_status: 0, // 0体験予約を検討中 / 1体験予約をして来店予定 / 2体験予約したが来店しなかった / 3体験予約して来店したが入会しなかった / 4体験予約して来店して入会した
          member: false, // 会員かどうか
          instructor: false,
          initial: false, // 初期費用を支払ったかどうか
          initial_discount: 100,
          trial_discount: 50,
          plan: 0,
          sex: sex,
          fixies: [],
          allowEmail: true,
          login: false,
          active: true,
          path: path,
          reason: reason, // 新しいフィールドを追加
          message: message,
          createdAt: new Date(),
          updatedAt: new Date(),
        },
        { merge: true }
      );

      const studioRef = doc(firestore, 'studios', studioId);
      await setDoc(studioRef, { users: arrayUnion(userRef) }, { merge: true });

      setRegistering(false);

      toast({
        title: '予約用アカウント作成',
        description: '成功しました。',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      const embeds = [
        {
          title: '体験予約の入力中',
          description: `以下のお客様が体験レッスンを検討しています`,
          color: 32896,
          fields: [
            { name: 'お客さま氏名', value: `${fullName}`, inline: true },
            { name: 'メールアドレス', value: email, inline: true },
          ],
          footer: { text: 'アカウント作成時刻' },
          timestamp: new Date().toISOString(),
        },
      ];
      await sendDiscordNotification('trial', embeds);
      await sendDiscordNotification('audit', embeds);
      navigate('/reserve');
    } catch (error) {
      const errorMessageMap = {
        'auth/email-already-in-use':
          'このメールアドレスは既に使用されています。',
        'auth/invalid-email': 'メールアドレスが無効です。',
        'auth/operation-not-allowed': 'この操作は許可されていません。',
        'auth/weak-password': 'パスワードが弱すぎます。',
      };
      const translatedMessage =
        errorMessageMap[error.code] || '未定義のエラーが発生しました。';
      if (error.code === 'auth/email-already-in-use') {
        if (currentUser) {
          navigate('/reserve');
          return;
        }
        navigate('/login');
      }
      toast({
        title: 'アカウント作成エラー',
        description: translatedMessage,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (currentUser?.trialId && !currentUser?.initial) {
      navigate('/waiting');
    }
    if (currentUser?.initial) {
      navigate('/login');
    }
    if (currentUser) {
      currentUser.trialId ? navigate('/waiting') : setIsAlertOpen(true);
    }
    const studiosCollection = collection(firestore, 'studios');
    const unsubscribe = onSnapshot(
      studiosCollection,
      snapshot => {
        const studiosArray = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        const openStudiosArray = studiosArray.filter(
          studio => studio.open === true
        );
        setStudios(openStudiosArray);
        setStudioId(openStudiosArray[0]?.id);
        setStudioName(openStudiosArray[0]?.name);
      },
      error => console.error('Error fetching studios:', error)
    );

    return () => unsubscribe();
  }, [currentUser, currentUser?.initial, currentUser?.trialId, navigate]);

  const handleNextStep = () => {
    if (!isStepCompleted(step)) return;

    setCompletedSteps(prev => {
      const newCompletedSteps = [...prev];
      newCompletedSteps[step - 1] = true;
      return newCompletedSteps;
    });
    setStep(step + 1);
  };

  const handleSignOut = async () => {
    const confirmSignOut = window.confirm(
      'このメールアドレスでは二度と予約できなくなります。本当に別のアドレスで予約を取り直しますか？'
    );

    if (confirmSignOut) {
      try {
        await signOut(auth);
        navigate('/login');
      } catch (error) {
        console.error('Error signing out: ', error);
      }
    } else {
      console.log('サインアウトがキャンセルされました。');
    }
  };

  if (registering) return <LoadingModal text="サーバー通信中..." />;

  const isStepCompleted = step => {
    switch (step) {
      case 1:
        if (studioId === '' || sex === '') {
          toast({
            title: 'エラー',
            description: 'スタジオ名と性別を選択してください。',
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
          return false;
        }
        return true;
      case 2:
        if (fullName === '' || fullKana === '' || displayName === '') {
          toast({
            title: 'エラー',
            description: 'フルネームとフルカナを入力してください。',
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
          return false;
        }
        return true;
      case 3:
        if (
          phoneNumber === '' ||
          email === '' ||
          !isValidEmail(email) ||
          !isValidPhoneNumber(phoneNumber)
        ) {
          toast({
            title: 'エラー',
            description: 'メールアドレスをご確認ください',
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
          return false;
        }
        return true;
      case 4:
        if (
          password === '' ||
          confirmPassword === '' ||
          password.length < 8 ||
          password !== confirmPassword
        ) {
          toast({
            title: 'エラー',
            description:
              'パスワードは8文字以上で確認と一致する必要があります。',
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
          return false;
        }
        return true;
      case 5:
        if (path === '' || reason === '') {
          toast({
            title: 'エラー',
            description: 'きっかけを選択してください。',
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
          return false;
        }
        return true;
      default:
        return false;
    }
  };

  const stepContent = () => {
    switch (step) {
      case 1:
        return (
          <>
            <Text
              fontSize="md"
              color="gray.800"
              fontWeight={'bold'}
              mt="0"
              ml="0"
            >
              体験予約は数分程度で完了します✨
            </Text>
            <Text fontSize="md" color="red" fontWeight={'bold'} mt="0" ml="0">
              西荻窪徒歩3分！2号店が11月下旬OPEN🎉
            </Text>
            <Flex flexDirection="column" alignItems="start" width="full">
              <FormLabel fontSize="12" mb="1">
                スタジオ名 <span style={{ color: 'red' }}>*</span>
              </FormLabel>
              <Select
                value={studioId}
                onChange={e => setSelectedStudio(e.target.value)}
              >
                {studios?.map(s => (
                  <option key={s?.id} value={s?.id}>
                    {s?.name}
                  </option>
                ))}
              </Select>
            </Flex>
            <Flex flexDirection="column" alignItems="start" width="full">
              <FormLabel fontSize="12" mb="1">
                性別 <span style={{ color: 'red' }}>*</span>
              </FormLabel>
              <Select
                id="sex"
                placeholder="身体の性別を選択してください"
                value={sex}
                onChange={e => setSex(e.target.value)}
                disabled={true}
              >
                <option value="female">女性</option>
              </Select>
              <Text fontSize="xs" color="gray.500" mt="1" ml="1">
                medyのマシンピラティスは女性専門サービスです🙌
              </Text>
            </Flex>
            <Button onClick={handleNextStep} colorScheme="blue" width="full">
              次へ
            </Button>
          </>
        );
      case 2:
        return (
          <>
            <Flex flexDirection="column" alignItems="start" width="full">
              <FormLabel fontSize="12" mb="1">
                フルネーム（漢字） <span style={{ color: 'red' }}>*</span>
              </FormLabel>
              <Input
                placeholder="フルネーム"
                value={fullName}
                onChange={e => setFullName(e.target.value)}
              />
              <Text fontSize="xs" color="gray.500" mt="1" ml="1">
                日本人ではない方は母語でも大丈夫です。
              </Text>
            </Flex>
            <Flex flexDirection="column" alignItems="start" width="full">
              <FormLabel fontSize="12" mb="1">
                フルネーム（ひらがな） <span style={{ color: 'red' }}>*</span>
              </FormLabel>
              <Input
                placeholder="フルネーム（かな）"
                value={fullKana}
                onChange={e => setFullKana(e.target.value)}
              />
              <Text fontSize="xs" color="gray.500" mt="1" ml="1">
                ひらがなが不明な方はローマ字で大丈夫です。
              </Text>
            </Flex>
            <Flex flexDirection="column" alignItems="start" width="full">
              <FormLabel fontSize="12" mb="1">
                LINE表示名 <span style={{ color: 'red' }}>*</span>
              </FormLabel>
              <Input
                placeholder="LINEで設定しているお名前"
                value={displayName}
                onChange={e => setDisplayName(e.target.value)}
              />
              <Text fontSize="xs" color="gray.500" mt="1" ml="1">
                公式ラインで見つけるために必要になります。
              </Text>
            </Flex>
            <Button onClick={handleNextStep} colorScheme="blue" width="full">
              次へ
            </Button>
            <Button onClick={() => setStep(1)} colorScheme="gray" width="full">
              戻る
            </Button>
          </>
        );
      case 3:
        return (
          <>
            <Flex flexDirection="column" alignItems="start" width="full">
              <FormLabel fontSize="12" mb="1">
                電話番号（半角数字のみ） <span style={{ color: 'red' }}>*</span>
              </FormLabel>
              <Input
                placeholder="SMSが届く電話番号"
                value={phoneNumber}
                onChange={e =>
                  setPhoneNumber(normalizePhoneNumber(e.target.value))
                }
              />
              <Text fontSize="xs" color="gray.500" mt="1" ml="1">
                公式ラインで連絡できない際に使用します。
              </Text>
            </Flex>
            <Flex flexDirection="column" alignItems="start" width="full">
              <FormLabel fontSize="12" mb="1">
                メールアドレス <span style={{ color: 'red' }}>*</span>
              </FormLabel>
              <Input
                placeholder="普段使いのアドレス"
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
              <Text fontSize="xs" color="gray.500" mt="1" ml="1">
                確認メールが届くものをご入力ください。
              </Text>
            </Flex>
            <Flex flexDirection="column" alignItems="start" width="full">
              <FormLabel fontSize="12" mb="1">
                メールアドレス（確認用）
              </FormLabel>
              <Input
                placeholder="確認用に入力内容が表示されます。"
                value={email}
                onChange={e => setEmail(e.target.value)}
                fontWeight={'bold'}
              />
              <Text fontSize="xs" color="gray.500" mt="1" ml="1">
                次へ進む前に間違いがないか確認ください。
              </Text>
            </Flex>
            <Button onClick={handleNextStep} colorScheme="blue" width="full">
              次へ
            </Button>
            <Button onClick={() => setStep(2)} colorScheme="gray" width="full">
              戻る
            </Button>
          </>
        );
      case 4:
        return (
          <>
            <Flex flexDirection="column" alignItems="start" width="full">
              <FormLabel fontSize="12" mb="1">
                パスワード（８文字以上） <span style={{ color: 'red' }}>*</span>
              </FormLabel>
              <InputGroup>
                <Input
                  pr="4.5rem"
                  type={showPassword ? 'text' : 'password'}
                  placeholder="パスワード"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                />
                <InputRightElement width="4.5rem">
                  <Button
                    h="1.75rem"
                    size="sm"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? '隠す' : '見る'}
                  </Button>
                </InputRightElement>
              </InputGroup>
              <Text fontSize="xs" color="gray.500" mt="1" ml="1">
                体験の変更やキャンセル等に必要です。
              </Text>
            </Flex>
            <Flex flexDirection="column" alignItems="start" width="full" mt="">
              <FormLabel fontSize="12" mb="1">
                パスワードの確認 <span style={{ color: 'red' }}>*</span>
              </FormLabel>
              <InputGroup>
                <Input
                  pr="4.5rem"
                  type={showConfirmPassword ? 'text' : 'password'}
                  placeholder="パスワードの確認"
                  value={confirmPassword}
                  onChange={e => setConfirmPassword(e.target.value)}
                />
                <InputRightElement width="4.5rem">
                  <Button
                    h="1.75rem"
                    size="sm"
                    onClick={toggleConfirmPasswordVisibility}
                  >
                    {showConfirmPassword ? '隠す' : '見る'}
                  </Button>
                </InputRightElement>
              </InputGroup>
              <Text fontSize="xs" color="gray.500" mt="1" ml="1">
                忘れないようにしましょう！
              </Text>
            </Flex>
            <Button onClick={handleNextStep} colorScheme="blue" width="full">
              次へ
            </Button>
            <Button onClick={() => setStep(3)} colorScheme="gray" width="full">
              戻る
            </Button>
          </>
        );
      case 5:
        return (
          <>
            <Flex flexDirection="column" alignItems="start" width="full">
              <FormLabel fontSize="12" mb="1">
                medyを知ったきっかけ <span style={{ color: 'red' }}>*</span>
              </FormLabel>
              <Select
                value={path}
                onChange={e => setPath(e.target.value)}
                placeholder="選択してください"
              >
                <option key="search" value="ウェブ検索">
                  ウェブ検索
                </option>
                <option key="instagram" value="Instagram">
                  Instagram
                </option>
                <option key="signborad" value="看板">
                  看板
                </option>
                <option key="leaflet" value="チラシ">
                  チラシ
                </option>
                <option key="map" value="Googleマップ">
                  Googleマップ
                </option>
                <option key="friend" value="友人の紹介">
                  友人の紹介
                </option>
              </Select>
            </Flex>
            <Flex flexDirection="column" alignItems="start" width="full">
              <FormLabel fontSize="12" mb="1">
                体験申し込みのきっかけ <span style={{ color: 'red' }}>*</span>
              </FormLabel>{' '}
              {/* 新しいラベル */}
              <Select
                value={reason}
                onChange={e => setReason(e.target.value)}
                placeholder="選択してください"
              >
                <option value="すぐ通い始めたい">すぐ通い始めたい</option>
                <option value="他と比較したい">他と比較したい</option>
                <option value="初めてで気になって">初めてで気になって</option>
              </Select>
            </Flex>
            <Flex flexDirection="column" alignItems="start" width="full">
              <FormLabel fontSize="12" mb="1">
                事前に伝えておきたいこと
              </FormLabel>
              <Textarea
                placeholder="姿勢が悪いので背筋をしゃんとしたいです！普段あまり運動しないので、健康のために始めたいと思っています。"
                value={message}
                onChange={e => setMessage(e.target.value)}
                rows={3}
              />
            </Flex>
            <Button
              onClick={signUpWithEmailAndPassword}
              colorScheme="blue"
              width="full"
            >
              ご予約日時の選択へ
            </Button>
            <Button onClick={() => setStep(4)} colorScheme="gray" width="full">
              戻る
            </Button>
          </>
        );
      default:
        return null;
    }
  };

  const stepCircles = () => {
    const steps = [1, 2, 3, 4, 5];
    return (
      <HStack spacing={4} justify="center" mb={4}>
        {steps.map(num => (
          <Circle
            key={num}
            size="40px"
            bg={
              completedSteps[num - 1]
                ? 'green.500'
                : num === step
                ? 'blue.500'
                : 'gray.200'
            }
            color="white"
            cursor="pointer"
            onClick={() => setStep(num)}
          >
            {completedSteps[num - 1] ? '✓' : num}
          </Circle>
        ))}
      </HStack>
    );
  };

  return (
    <Container
      backgroundColor={'#ede9e5'}
      borderRadius={'10'}
      mt={4}
      pt={8}
      pb="4"
    >
      <Logo text="体験レッスンご予約の受付" />
      {stepCircles()}
      <Box
        borderWidth="0px"
        borderRadius="xl"
        overflow="hidden"
        p={4}
        mt={4}
        backgroundColor={'#fdfdfd'}
        boxShadow="sm"
      >
        <VStack spacing="12px">{stepContent()}</VStack>
      </Box>

      <AlertDialog
        isOpen={isAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsAlertOpen(false)}
      >
        <AlertDialogOverlay>
          <motion.div>
            <AlertDialogContent
              rounded={'xl'}
              position="fixed"
              bottom="0"
              left="0%"
              transform="translateX(-50%)"
              mb="-3"
            >
              <AlertDialogHeader fontSize="lg" fontWeight="bold" mt={2}>
                {currentUser?.fullName}さま、ご確認ください🤲
              </AlertDialogHeader>
              <AlertDialogBody>
                <Text mb={2}>以前にご予約したことがあるようです🙆‍♀️</Text>
                <Text mb={4} mt={4}>
                  Email: <b>{currentUser?.email}</b>
                </Text>
                <Text>前回の入力情報を利用して予約しますか？</Text>
              </AlertDialogBody>
              <AlertDialogFooter>
                <Button
                  colorScheme="teal"
                  onClick={() => navigate('/reserve')}
                  mb={4}
                >
                  前回の情報で予約する
                </Button>
                <Button
                  colorScheme="gray"
                  onClick={handleSignOut}
                  ml={2}
                  mb={4}
                >
                  別のアドレスにする
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </motion.div>
        </AlertDialogOverlay>
      </AlertDialog>
    </Container>
  );
};

export default Trial;
