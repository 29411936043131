import React, { useState, useEffect, useCallback } from 'react';
import {
  Text,
  Container,
  Button,
  Box,
  VStack,
  HStack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from '@chakra-ui/react';
import {
  firestore,
  collection,
  query,
  where,
  getDocs,
  addDoc,
  updateDoc,
  doc,
} from '../firebaseConfig';
import { useAuth } from '../AuthProvider';
import Logo from './Logo';
import LoadingModal from '../components/LoadingModal';
import { useNavigate } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import { sendDiscordNotification } from '../utils/discord';
import { addEventToGoogleCalendar } from '../utils/googleCalendar';

const Reserve = () => {
  const { currentUser } = useAuth();
  const [fetching, setFetching] = useState(false);
  const [reservations, setReservations] = useState([]);
  const [selectedDateTime, setSelectedDateTime] = useState(null);
  const [dateOffset, setDateOffset] = useState(0);
  const [processing, setProcessing] = useState(false);
  const navigate = useNavigate();

  const getNextDays = offset => {
    const days = [];
    const today = new Date();
    today.setDate(today.getDate() + offset);
    let currentMonth = today.getMonth();
    for (let i = 1; i < 8; i++) {
      const day = new Date(today);
      day.setDate(today.getDate() + i);
      if (day.getMonth() !== currentMonth) {
        currentMonth = day.getMonth();
        days.push({ date: day, newMonth: true });
      } else {
        days.push({ date: day, newMonth: false });
      }
    }
    return days;
  };

  const combineDateTime = (date, hour, minute) => {
    if (typeof date === 'string') {
      date = new Date(date);
      console.log('Converted date string to Date object:', date);
    }

    if (!(date instanceof Date) || isNaN(date)) {
      console.error('Invalid date object:', date);
      return null;
    }

    const dateStr = date
      .toLocaleDateString('ja-JP', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
      .replace(/\//g, '-');

    const dateTimeStr = `${dateStr}T${String(hour).padStart(2, '0')}:${String(
      minute
    ).padStart(2, '0')}`;

    console.log('Combined date and time:', dateTimeStr);

    return dateTimeStr;
  };

  const fetchReservations = useCallback(async () => {
    if (!currentUser) return;

    setFetching(true);
    const reservationsRef = collection(firestore, 'reservations');
    const q = query(
      reservationsRef,
      where('studioId', '==', currentUser.studioId),
      where('active', '==', true),
      where('startDate', '>=', new Date().toISOString())
    );
    const querySnapshot = await getDocs(q);
    const reservations = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
    }));
    setReservations(reservations);
    setFetching(false);
  }, [currentUser]);

  const days = getNextDays(dateOffset) || [];

  const timeSlots = [
    { hour: 8, minute: 0 },
    { hour: 9, minute: 0 },
    { hour: 10, minute: 0 },
    { hour: 11, minute: 0 },
    { hour: 12, minute: 30 },
    { hour: 13, minute: 30 },
    { hour: 14, minute: 30 },
    { hour: 15, minute: 30 },
    { hour: 16, minute: 30 },
    { hour: 17, minute: 30 },
    { hour: 19, minute: 0 },
    { hour: 20, minute: 0 },
    { hour: 21, minute: 0 },
    { hour: 22, minute: 0 },
  ];

  const isTimeSlotReserved = (date, hour, minute) => {
    const slotStart = new Date(date);
    slotStart.setHours(hour, minute, 0, 0);
    const slotEnd = new Date(slotStart.getTime() + 60 * 60 * 1000); // 1 hour duration

    return reservations.some(reservation => {
      const reservationStart = new Date(reservation.startDate);
      const reservationEnd = new Date(reservation.endDate);

      return slotStart < reservationEnd && slotEnd > reservationStart;
    });
  };

  const getMonthRange = days => {
    if (!days || days.length === 0) return '';
    const months = [...new Set(days.map(day => day.date.getMonth() + 1))];
    return months.length > 1
      ? `${months[0]}月・${months[1]}月`
      : `${months[0]}月`;
  };

  const isShiftAvailable = useCallback(
    (date, hour, minute) => {
      if (!currentUser || !currentUser.studio) {
        window.location.reload();
        return false;
      }
      const weekday = ['日', '月', '火', '水', '木', '金', '土'][date.getDay()];
      const timeStr = `${String(hour).padStart(2, '0')}${String(
        minute
      ).padStart(2, '0')}`;
      const shiftKey = `${weekday}${timeStr}`;
      return (
        currentUser.studio.shifts[shiftKey] &&
        currentUser.studio.shifts[shiftKey].length > 0
      );
    },
    [currentUser]
  );

  const isFixedSlot = useCallback(
    (date, hour, minute) => {
      if (!currentUser || !currentUser.studio) {
        window.location.reload();
        return false;
      }
      const weekday = ['日', '月', '火', '水', '木', '金', '土'][date.getDay()];
      const timeStr = `${String(hour).padStart(2, '0')}${String(
        minute
      ).padStart(2, '0')}`;

      // Provide a default empty array if fixies is undefined
      const fixies = currentUser.studio.fixies || [];

      return fixies.some(
        fixie => fixie.weekday === weekday && fixie.start === timeStr
      );
    },
    [currentUser]
  );

  const formatDateTime = (date, hour, minute) => {
    const day = new Date(date);
    const formattedDate = `${day.getFullYear()}年${
      day.getMonth() + 1
    }月${day.getDate()}日`;
    return `${formattedDate} ${String(hour).padStart(2, '0')}:${String(
      minute
    ).padStart(2, '0')}`;
  };

  const handleReserveClick = (date, hour, minute) => {
    const selected =
      selectedDateTime &&
      selectedDateTime.date.toDateString() === date.toDateString() &&
      selectedDateTime.hour === hour &&
      selectedDateTime.minute === minute;

    if (selected) {
      setSelectedDateTime(null);
    } else {
      if (isTimeSlotReserved(date, hour, minute)) {
        alert('この時間帯は他の人の予約枠です。');
      } else {
        const newSelectedDateTime = { date: new Date(date), hour, minute };
        setSelectedDateTime(newSelectedDateTime);
        localStorage.setItem(
          'selectedDateTime',
          JSON.stringify(newSelectedDateTime)
        );
      }
    }
  };

  const confirmReservation = async () => {
    setProcessing(true);
    const startDateTime = combineDateTime(
      selectedDateTime.date,
      selectedDateTime.hour,
      selectedDateTime.minute
    );
    const endDateTime = combineDateTime(
      selectedDateTime.date,
      selectedDateTime.hour + 1,
      selectedDateTime.minute
    );

    try {
      const eventData = await addEventToGoogleCalendar(
        currentUser,
        '体験',
        '体験予約画面',
        startDateTime,
        endDateTime
      );

      const weekday = ['日', '月', '火', '水', '木', '金', '土'][
        selectedDateTime.date.getDay()
      ];
      const timeStr = `${String(selectedDateTime.hour).padStart(
        2,
        '0'
      )}${String(selectedDateTime.minute).padStart(2, '0')}`;
      const shiftKey = `${weekday}${timeStr}`;
      const instructorId = currentUser.studio.shifts[shiftKey]?.[0];

      const reservation = await addDoc(collection(firestore, 'reservations'), {
        startDate: startDateTime,
        endDate: endDateTime,
        userId: currentUser.uid,
        description: currentUser.fullName,
        studioId: currentUser.studioId,
        ticketId: null,
        eventId: eventData.id,
        visited: false,
        active: true,
        changed: false,
        type: 'trial',
        instructorId: instructorId,
      });

      const userRef = doc(firestore, 'users', currentUser.uid);
      await updateDoc(userRef, {
        trialId: reservation.id,
        trialPaid: true,
      });

      const embeds = [
        {
          title: '新規体験のご予約が入りました✨',
          description: `体験レッスン予約ページからお客さま自身が入力しました。`,
          color: 15158332,
          fields: [
            {
              name: '氏名（漢字）',
              value: currentUser.fullName,
              inline: true,
            },
            {
              name: '氏名（かな）',
              value: currentUser.fullKana,
              inline: true,
            },
            {
              name: 'LINE表示名',
              value: currentUser.displayName,
              inline: true,
            },
            {
              name: '電話番号',
              value: currentUser.phoneNumber,
              inline: true,
            },
            {
              name: 'メールアドレス',
              value: currentUser.email,
              inline: true,
            },
            {
              name: '認知経路',
              value: currentUser.path,
              inline: true,
            },
            {
              name: 'モチベーション',
              value: currentUser.reason,
              inline: true,
            },
            {
              name: '連絡事項',
              value: currentUser.message || 'なし',
              inline: true,
            },
            {
              name: '体験費用',
              value: currentUser.trialPaid ? '支払い済み' : '未払い',
              inline: true,
            },
            {
              name: '開始時刻',
              value: `${startDateTime.replace('T', ' ')}`,
              inline: true,
            },
            {
              name: '終了時刻',
              value: `${endDateTime.replace('T', ' ')}`,
              inline: true,
            },
          ],
          footer: { text: 'UID: ' + currentUser.uid },
          timestamp: new Date().toISOString(),
        },
      ];

      await sendDiscordNotification(currentUser.studio.slug, embeds);
      await sendDiscordNotification('trial', embeds);
      await sendDiscordNotification('audit', embeds);

      setProcessing(false);
      navigate('/waiting');
    } catch (error) {
      window.alert(
        'この枠は何らかの理由で予約できないため、別の日時でご予約ください。'
      );
      console.error('予約の追加に失敗しました', error);

      const errorEmbeds = [
        {
          title: '体験費用の支払い後のエラー',
          description: `体験費用はお支払いされましたが、予約の追加に失敗しました。`,
          color: 16711680,
          fields: [
            {
              name: '対象者',
              value: currentUser?.fullName,
              inline: true,
            },
          ],
          footer: {
            text: 'USER ID:' + currentUser?.uid,
          },
          timestamp: new Date().toISOString(),
        },
      ];
      await sendDiscordNotification('trial', errorEmbeds);
      await sendDiscordNotification('audit', errorEmbeds);

      setProcessing(false);
      navigate('/reserve');
    }
  };

  const handleConfirm = () => {
    if (currentUser.trialPaid) {
      confirmReservation();
      return;
    }

    navigate('/trial-confirm', { state: { selectedDateTime } });
  };

  const handlePrevDays = () => {
    setDateOffset(prev => Math.max(prev - 7, 0));
  };

  const handleNextDays = () => {
    setDateOffset(prev => Math.min(prev + 7, 28)); // Up to 4 weeks ahead
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => handleNextDays(),
    onSwipedRight: () => handlePrevDays(),
  });

  useEffect(() => {
    fetchReservations();
  }, [fetchReservations]);

  if (fetching)
    return <LoadingModal text="予約が可能な時間を問い合わせ中..." />;
  if (processing) return <LoadingModal text="予約を処理中..." />;

  return (
    <Container
      backgroundColor={'#ede9e5'}
      borderRadius={'10'}
      mt={4}
      pt={8}
      pb="4"
    >
      <Logo text="日時選択画面" />
      {currentUser.studio?.name === 'medy 西荻窪 STUDIO' && (
        <Text fontSize="12" color="red" fontWeight={'bold'}>
          ◎
          インストラクター配属タイミングの兼ね合いで、11月下旬以降いくつかの曜日のみ公開中です！（medy
          西荻窪スタジオ）
        </Text>
      )}
      <Box
        borderWidth="0px"
        borderRadius="xl"
        overflow="hidden"
        p={4}
        mt={4}
        backgroundColor={'#fdfdfd'}
        boxShadow="sm"
        {...handlers}
      >
        <HStack justifyContent="space-between" mb={2}>
          <Button
            size="sm"
            onClick={handlePrevDays}
            disabled={dateOffset === 0}
            colorScheme={dateOffset === 0 ? 'gray' : 'teal'}
          >
            前へ
          </Button>
          <Text fontWeight="bold">{getMonthRange(days)}</Text>
          <Button
            size="sm"
            onClick={handleNextDays}
            disabled={dateOffset >= 21}
            colorScheme={dateOffset >= 21 ? 'gray' : 'teal'}
          >
            次へ
          </Button>
        </HStack>
        <Table variant="simple" size="xs">
          <Thead>
            <Tr>
              <Th style={{ textAlign: 'center', fontSize: 12 }}>時間</Th>
              {days.length > 0 &&
                days.map((day, index) => (
                  <Th
                    style={{
                      textAlign: 'center',
                      marginBottom: '4px',
                      fontSize: 10,
                      color:
                        day.date.getDay() === 0
                          ? 'red'
                          : day.date.getDay() === 6
                          ? 'blue'
                          : 'black',
                    }}
                    key={day.date.toDateString()}
                  >
                    {`${day.date.getDate()}日 (${
                      ['日', '月', '火', '水', '木', '金', '土'][
                        day.date.getDay()
                      ]
                    })`}
                  </Th>
                ))}
            </Tr>
          </Thead>
          <Tbody>
            {timeSlots.length > 0 &&
              timeSlots.map(slot => (
                <Tr key={`${slot.hour}:${slot.minute}`}>
                  <Td
                    style={{
                      textAlign: 'center',
                      fontSize: 12,
                      paddingRight: 4,
                    }}
                  >{`${String(slot.hour).padStart(2, '0')}:${String(
                    slot.minute
                  ).padStart(2, '0')}`}</Td>
                  {days.map(day => (
                    <Td
                      key={`${day.date.toDateString()}-${slot.hour}:${
                        slot.minute
                      }`}
                      onClick={() =>
                        handleReserveClick(day.date, slot.hour, slot.minute)
                      }
                      style={{
                        fontSize: 12,
                        cursor:
                          !isFixedSlot(day.date, slot.hour, slot.minute) &&
                          !isTimeSlotReserved(
                            day.date,
                            slot.hour,
                            slot.minute
                          ) &&
                          isShiftAvailable(day.date, slot.hour, slot.minute)
                            ? 'pointer'
                            : 'default',
                        textAlign: 'center',
                        backgroundColor:
                          selectedDateTime &&
                          selectedDateTime.date.toDateString() ===
                            day.date.toDateString() &&
                          selectedDateTime.hour === slot.hour &&
                          selectedDateTime.minute === slot.minute
                            ? '#afc7fb'
                            : !isFixedSlot(day.date, slot.hour, slot.minute) &&
                              isShiftAvailable(
                                day.date,
                                slot.hour,
                                slot.minute
                              ) &&
                              !isTimeSlotReserved(
                                day.date,
                                slot.hour,
                                slot.minute
                              )
                            ? '#eafffa'
                            : 'silver',
                      }}
                    >
                      {isFixedSlot(day.date, slot.hour, slot.minute) ||
                      isTimeSlotReserved(day.date, slot.hour, slot.minute) ||
                      !isShiftAvailable(day.date, slot.hour, slot.minute)
                        ? '×'
                        : '◯'}
                    </Td>
                  ))}
                </Tr>
              ))}
          </Tbody>
        </Table>
      </Box>
      <VStack spacing={2} mt={4} align="center">
        <Text fontWeight={'bold'}>
          {currentUser.fullName || currentUser.fullName}さま
        </Text>
        <Text>{currentUser.email}</Text>
        <Text fontWeight={'bold'}>店舗</Text>
        <Text>{currentUser.studio?.name}</Text>
        <Text fontWeight={'bold'}>日時</Text>
        {selectedDateTime && (
          <Text>
            {formatDateTime(
              selectedDateTime.date,
              selectedDateTime.hour,
              selectedDateTime.minute
            )}
          </Text>
        )}
        {!selectedDateTime && (
          <>
            <Text fontSize="12" color="red">
              体験レッスンの希望時間を選択してください
            </Text>
          </>
        )}
        <Button
          colorScheme="blue"
          my={2}
          onClick={handleConfirm}
          isDisabled={!selectedDateTime || !currentUser}
        >
          {currentUser.trialPaid ? '予約を確定する' : '確認画面へ'}
        </Button>
        <Text fontSize="12" color="red">
          {currentUser.trialPaid
            ? 'お支払い完了までの間に希望したいた時間に他の方の予約が入ったか、ご自身で変更する場合は再選択が必要です。'
            : ''}
        </Text>
      </VStack>
    </Container>
  );
};

export default Reserve;
