import React, { useState, useEffect } from 'react';
import { useAuth } from '../AuthProvider';
import { useNavigate } from 'react-router-dom';
import { getFirestore, collection, getDocs } from 'firebase/firestore'; // Firestore imports
import { Box, Container, Button, Flex, Spacer, Text } from '@chakra-ui/react';
import Logo from './Logo';

const Manage = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  // const { isOpen, onOpen, onClose } = useDisclosure();
  // const [modalContent, setModalContent] = useState({
  //   header: '',
  //   imagePath: '',
  // });

  // // モーダルを開く関数をカスタマイズして、テキストと画像パスを渡す
  // const openModal = (header, imagePath) => {
  //   setModalContent({ header, imagePath });
  //   onOpen();
  // };

  // const onClickTasks = () => navigate('/tasks');
  // const onClickAddReservation = () => navigate('/add-reservation');
  const onClickStudio = studioId => {
    navigate(`/studios/${studioId}`);
  };

  // ログアウト関数を作成
  const handleLogout = async () => {
    navigate('/home');
  };

  // State for studios
  const [studios, setStudios] = useState([]);

  // Fetch studios from Firestore
  useEffect(() => {
    const fetchStudios = async () => {
      const db = getFirestore(); // Initialize Firestore
      const studiosCollection = collection(db, 'studios');
      const studiosSnapshot = await getDocs(studiosCollection);
      const studiosList = studiosSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setStudios(studiosList);
    };

    fetchStudios();
  }, []);

  return (
    <Container backgroundColor={'#ede9e5'} borderRadius={'10'} mt={4} pt={8}>
      <Logo text="会員管理システム" />
      <Box
        borderWidth="0px"
        borderRadius="xl"
        overflow="hidden"
        p={4}
        mt={4}
        backgroundColor={'#fdfdfd'}
        boxShadow="sm"
      >
        <Flex alignItems="center" mb="4">
          <Flex flexDirection="column" alignItems="flex-start">
            <Text fontSize={20}>
              <b>{currentUser?.fullName || ''}</b>
            </Text>
            <Text fontSize="12">{currentUser?.email || ''}</Text>
          </Flex>
          <Spacer /> {/* 左のコンテンツと右のボタンの間にスペースを作る */}
          <Button
            size="xs"
            colorScheme="gray"
            onClick={handleLogout}
            variant={'outlineBlack'}
            isDisabled
          >
            {currentUser?.admin
              ? '管理者'
              : currentUser?.instructor
              ? 'インストラクター'
              : '権限なし'}
          </Button>
        </Flex>
        {studios.length > 0 ? (
          studios.map(studio => (
            <Box
              key={studio.id}
              borderWidth="1px"
              borderRadius="md"
              p={4}
              mt={2}
              onClick={() => onClickStudio(studio.id)}
              cursor="pointer"
            >
              <Text>{studio.name || '名前未設定'}</Text>
            </Box>
          ))
        ) : (
          <Text>スタジオが見つかりません</Text>
        )}
        <Text color={'gray'} fontSize="xs" my={2} textAlign={'center'}>
          タップすると予約受付枠や会員の操作が可能
        </Text>
      </Box>
      {/* <Flex justifyContent="space-between" mt={4}>
        <Button size="sm" onClick={onClickTasks} variant={'outlineBlack'}>
          各種タスク
        </Button>
        <Button size="sm" colorScheme="green" onClick={onClickTasks}>
          稼働実績
        </Button>
        <Button
          size="sm"
          colorScheme="gray"
          onClick={onClickAddReservation}
          variant={'stylishBlack'}
        >
          予定の追加
        </Button>
      </Flex> */}
      {/* <Button
        size="md"
        onClick={() =>
          openModal('◉ 説明会スライド - 2024/03/30', '/slide_20240330.png')
        }
        colorScheme="telegram"
        width={'100%'}
        mt={4}
      >
        最新のスライドを開く
      </Button> */}
      {/* <Button
        size="md"
        onClick={() => navigate('/reports')}
        colorScheme="telegram"
        width={'100%'}
        mt={4}
      >
        ✍️レッスン報告の記入
      </Button>
      <Button
        size="md"
        onClick={() =>
          openModal('◉ 体験のお会計 - 4,000円', '/stripe_trial_4000.png')
        }
        colorScheme="teal"
        width={'100%'}
        my={4}
      >
        体験レッスンのお会計
      </Button> */}
      {/* homeに戻るボタンをいかに追加 */}
      <Button
        size="md"
        onClick={handleLogout}
        colorScheme="gray"
        width={'100%'}
        my={4}
      >
        ホームに戻る
      </Button>

      {/* <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent width="92%">
          <ModalHeader mt="8" mb="4">
            {modalContent.header}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex justifyContent="center" mb={8}>
              <Image
                width={'70%'}
                src={modalContent.imagePath}
                alt={modalContent.header}
              />
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal> */}
    </Container>
  );
};

export default Manage;
