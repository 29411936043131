import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../AuthProvider';
import { v4 as uuid } from 'uuid';
import {
  Box,
  Container,
  Button,
  Flex,
  Spacer,
  Text,
  Badge,
  FormLabel,
  Select,
  IconButton,
} from '@chakra-ui/react';
import { RepeatIcon } from '@chakra-ui/icons';
import LoadingModal from './LoadingModal';
import Logo from './Logo';
// import { fetchReservationById } from '../utils/firestore';
import {
  firestore,
  collection,
  query,
  where,
  getDocs,
  doc,
  setDoc,
  onSnapshot,
} from '../firebaseConfig';

const Before = () => {
  const { currentUser } = useAuth();
  const [initialProduct, setInitialProduct] = useState({});
  const [initialPrice, setInitialPrice] = useState({});
  const [planProduct, setPlanProduct] = useState({});
  const [planPrice, setPlanPrice] = useState({});
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(4);
  const navigate = useNavigate();
  const location = useLocation();
  const INITIAL_PRICE = 40000;
  const TRIAL_PRICE = 8000;
  const PLAN_MENU = {
    8: '月８回プラン - 初月おすすめ',
    6: '月６回プラン - １番人気🌟',
    4: '月４回プラン',
    0: '回数券ご利用（都度払い）',
  };
  const PLAN_DESCRIPTION = {
    8: '通常9,900円のレッスンが7,700円です✨',
    6: '週１は専用枠で通い、２回は都度予約です😊',
    4: '効果の実感には最低でも週１は必要です🙌',
    0: 'アカウント維持のため月額550円かかります👌',
  };

  const getCheckoutSessionUrl = () =>
    localStorage.getItem('checkoutSessionUrl');
  const getCheckoutSessionUrl2 = () =>
    localStorage.getItem('checkoutSessionUrl2');

  const createCheckoutSession = useCallback(async () => {
    if (currentUser.trialPaid) {
      navigate('/initial-payment-success');
      return;
    }
    if (!currentUser || !initialPrice?.id || getCheckoutSessionUrl()) return;
    setLoading1(true);

    const sessionId = uuid();
    const checkoutSessionRef = doc(
      firestore,
      `customers/${currentUser.uid}/checkout_sessions/${sessionId}`
    );

    try {
      await setDoc(checkoutSessionRef, {
        mode: 'payment',
        line_items: [{ price: initialPrice?.id, quantity: 1 }],
        success_url: window.location.origin + '/initial-payment-success',
        cancel_url: window.location.origin + '/before',
      });

      // FirestoreのonSnapshotを使用して、セッションのURLがセットされたか監視し、ステートを更新
      const unsubscribe = onSnapshot(checkoutSessionRef, doc => {
        const { url } = doc.data();
        if (url) {
          localStorage.setItem('checkoutSessionUrl', url);
          window.location.assign(url);
        }
      });

      return () => {
        setLoading1(false);
        unsubscribe();
      };
    } catch (error) {
      console.error('Checkout session creation failed:', error);
    }
  }, [initialPrice, currentUser, navigate]);

  const createCheckoutSession2 = useCallback(async () => {
    if (!currentUser || !planPrice?.id || getCheckoutSessionUrl2()) return;

    setLoading2(true);

    const sessionId = uuid();
    const checkoutSessionRef = doc(
      firestore,
      `customers/${currentUser.uid}/checkout_sessions/${sessionId}`
    );

    try {
      await setDoc(checkoutSessionRef, {
        mode: 'subscription',
        line_items: [{ price: planPrice?.id, quantity: 1 }],
        success_url: window.location.origin + '/set-status-true',
        cancel_url: window.location.origin + '/before',
        metadata: {
          user_id: currentUser.uid,
          user_name: currentUser.fullName,
          studio_id: currentUser.studioId,
          studio_name: currentUser.studio.name,
        },
      });

      // FirestoreのonSnapshotを使用して、セッションのURLがセットされたか監視し、ステートを更新
      const unsubscribe = onSnapshot(checkoutSessionRef, doc => {
        const { url } = doc.data();
        if (url) {
          localStorage.setItem('checkoutSessionUrl2', url);
          window.location.assign(url);
        }
      });

      return () => {
        unsubscribe();
        setLoading2(false);
      };
    } catch (error) {
      console.error('Checkout session creation failed:', error);
    }
  }, [planPrice, currentUser]);

  const onClickInitialPayment = () => {
    if (currentUser.initial === true) {
      alert('お支払いは完了しています。');
      return;
    }
    if (getCheckoutSessionUrl()) {
      window.location.assign(getCheckoutSessionUrl());
    } else {
      createCheckoutSession();
    }
  };

  const onClickRegisterPlan = () => {
    if (currentUser.initial === false) {
      alert('初期費用のお会計が完了していません。');
      return;
    }
    // if (localStorage.plan === '0') {
    //   navigate('/set-status-true');
    //   return;
    // }
    if (getCheckoutSessionUrl2()) {
      window.location.assign(getCheckoutSessionUrl2());
    } else {
      createCheckoutSession2();
    }
  };

  const setPlanNumber = () => {
    if (!selectedPlan) {
      alert('プランを選択してください。');
      return;
    }
    // プランの再設定確認ダイアログ
    const existingPlan = localStorage.getItem('plan');
    if (existingPlan && existingPlan !== selectedPlan) {
      if (!window.confirm('既存のプランを新しいプランに更新しますか？')) {
        return;
      }
    }
    // localStorageにプランをセット
    localStorage.setItem('plan', selectedPlan);
    navigate('/set-plan', { state: { number: selectedPlan } });
  };

  useEffect(() => {
    // ローカルストレージからプランの値を読み込む
    const storedPlan = localStorage.getItem('plan');

    // ローカルストレージにプランが保存されていれば、それをselectedPlanステートにセット
    if (storedPlan) {
      setSelectedPlan(storedPlan);
    }
  }, []); // 空の依存配列を指定して、コンポーネントのマウント時にのみ実行されるようにする

  useEffect(() => {
    localStorage.removeItem('checkoutSessionUrl'); // チェックアウトの履歴機能をオフ
    localStorage.removeItem('checkoutSessionUrl2'); // チェックアウトの履歴機能をオフ
    // リロードフラグをチェック
    const reloadFlag = localStorage.getItem('reloadFlag');

    if (reloadFlag === 'true') {
      localStorage.setItem('reloadFlag', 'false'); // フラグをクリア
      navigate('/before', { state: { reload: false } }, { replace: true }); // 状態を更新
    } else if (location.state?.reload) {
      localStorage.setItem('reloadFlag', 'true'); // リロード前にフラグをセット
      window.location.reload();
    }
  }, [location, navigate]);

  useEffect(() => {
    // if (currentUser?.trialId?.length > 0)
    //   // currentUser.trialIdで予約をfirestoreのreservationsから取得する
    //   fetchReservationById(currentUser?.trialId).then(reservation => {
    //     //体験日時（日本時間）の文字列と現在時刻を比較して過ぎていない場合はwaitingへ遷移
    //     const now = new Date();
    //     const trialDate = new Date(reservation.startDate);
    //     console.log(now);
    //     console.log(trialDate);
    //     if (now < trialDate) {
    //       navigate('/waiting');
    //     }
    //   });
    const fetchInitialProducts = async () => {
      const productsCollectionRef = collection(firestore, 'products');
      const q = query(
        productsCollectionRef,
        where('active', '==', true),
        where('stripe_metadata_type', '==', 'live'),
        where('stripe_metadata_category', '==', 'initial')
      );
      const querySnapshot = await getDocs(q);
      const productsData = await Promise.all(
        querySnapshot.docs.map(async doc => {
          const productData = { id: doc.id, ...doc.data() };
          const pricesCollectionRef = collection(
            firestore,
            `products/${doc.id}/prices`
          );
          const priceQuerySnapshot = await getDocs(pricesCollectionRef);
          productData.prices = priceQuerySnapshot.docs.map(priceDoc => ({
            id: priceDoc.id,
            ...priceDoc.data(),
          }));
          return productData;
        })
      );
      const matchingPrices = productsData[0].prices.filter(
        price =>
          Number(price.metadata.initial_discount) ===
            currentUser.initial_discount &&
          Number(price.metadata.trial_discount) === currentUser.trial_discount
      );
      let matchingPrice; // 最終的に選ばれる価格情報を保持する変数
      // 事前にフィルタリングされたmatchingPricesの長さを確認
      if (matchingPrices.length === 1) {
        // 一致するpriceが1つだけの場合、その要素を直接セット
        matchingPrice = matchingPrices[0];
      } else if (matchingPrices.length > 1) {
        // 一致するpriceが複数ある場合、さらにcurrentUser.sexと一致するものを検索
        const sexMatchingPrice = matchingPrices.find(
          price => price.metadata.sex === currentUser.sex
        );
        // currentUser.sexと一致するものが見つかった場合に限り、その価格情報をセット
        // 見つからない場合は、matchingPriceはundefinedのままになります
        // 必要に応じて、デフォルト値をセットするなどの追加処理を行ってください
        if (sexMatchingPrice) {
          matchingPrice = sexMatchingPrice;
        }
      }
      setInitialPrice(matchingPrice);
      setInitialProduct(productsData[0]);
    };
    fetchInitialProducts();
    const fetchPlanProducts = async () => {
      const productsCollectionRef = collection(firestore, 'products');
      const q = query(
        productsCollectionRef,
        where('active', '==', true),
        where('stripe_metadata_type', '==', 'live'),
        where('stripe_metadata_category', '==', 'plan')
      );
      const querySnapshot = await getDocs(q);
      const productsData = await Promise.all(
        querySnapshot.docs.map(async doc => {
          const productData = { id: doc.id, ...doc.data() };
          const pricesCollectionRef = collection(
            firestore,
            `products/${doc.id}/prices`
          );
          const priceQuerySnapshot = await getDocs(pricesCollectionRef);
          productData.prices = priceQuerySnapshot.docs.map(priceDoc => ({
            id: priceDoc.id,
            ...priceDoc.data(),
          }));
          return productData;
        })
      );
      const matchingProductData = productsData.find(
        product => Number(product.metadata.plan) === currentUser?.plan
      );
      if (!matchingProductData) {
        console.log('No matching product found.');
        // ここで適切なデフォルト値を設定する
        setPlanProduct({});
        setPlanPrice({});
        return;
      }
      console.log('Matching product found:', matchingProductData);

      const matchingPlanPrices = matchingProductData.prices.filter(
        price => price.metadata.sex === currentUser?.sex
      );
      let matchingPlanPrice; // 最終的に選ばれる価格情報を保持する変数
      // 事前にフィルタリングされたmatchingPricesの長さを確認
      if (matchingPlanPrices.length === 1) {
        // 一致するpriceが1つだけの場合、その要素を直接セット
        matchingPlanPrice = matchingPlanPrices[0];
      } else if (matchingPlanPrices.length > 1) {
        // 一致するpriceが複数ある場合、さらにcurrentUser.sexと一致するものを検索
        const sexMatchingPrice = matchingPlanPrices.find(
          price => price.metadata.sex === currentUser?.sex
        );
        // currentUser.sexと一致するものが見つかった場合に限り、その価格情報をセット
        // 見つからない場合は、matchingPriceはundefinedのままになります
        // 必要に応じて、デフォルト値をセットするなどの追加処理を行ってください
        if (sexMatchingPrice) {
          matchingPlanPrice = sexMatchingPrice;
        }
      }
      setPlanPrice(matchingPlanPrice || {});
      setPlanProduct(matchingProductData);
    };
    fetchPlanProducts();

    if (currentUser?.initial === true && currentUser?.status === true) {
      navigate('/home');
    }
  }, [
    navigate,
    currentUser?.initial,
    currentUser.initial_discount,
    currentUser?.plan,
    currentUser.sex,
    currentUser?.status,
    currentUser.trial_discount,
    currentUser?.trialId,
  ]);

  if (loading1 || loading2) return <LoadingModal text="決済リンク生成中..." />;

  return (
    <>
      {location.state?.reload && <LoadingModal text="ページをリロード中..." />}
      {!location.state?.reload && (
        <Container
          backgroundColor={'#ede9e5'}
          borderRadius={'10'}
          mt={4}
          pt={8}
          pb={4}
        >
          <Logo text="3STEPで完了です！" />
          <Box
            borderWidth="0px"
            borderRadius="xl"
            overflow="hidden"
            p={4}
            mt={4}
            backgroundColor={'#fdfdfd'}
          >
            <Flex alignItems="center" mb="4">
              <Flex flexDirection="column" alignItems="flex-start">
                <Text fontSize={20}>
                  <b>{currentUser?.fullName || ''}</b>さま
                </Text>
                <Text fontSize="12">{currentUser?.email || ''}</Text>
              </Flex>
              <Spacer /> {/* 左のコンテンツと右のボタンの間にスペースを作る */}
              <IconButton
                aria-label="Reload"
                icon={<RepeatIcon />}
                size="sm"
                onClick={() => window.location.reload()}
                mr={2} // ボタンとテキストの間隔を調整
              />
              <Button
                size="xs"
                colorScheme="gray"
                variant={'outlineBlack'}
                disabled
              >
                入会手続き中
              </Button>
            </Flex>
            <Text fontWeight={'bold'}>お支払い情報の登録と決済</Text>
            <Box
              borderWidth="1px"
              borderRadius="xl"
              overflow="hidden"
              p={4}
              mt={4}
              mb={2}
              bg={currentUser.initial === true ? 'gray.100' : 'white'}
            >
              <Text fontSize={12}>
                <Badge
                  px={2}
                  py={1}
                  backgroundColor={'blue.300'}
                  color={'white'}
                  mb={2}
                >
                  1. {initialProduct?.name}
                </Badge>
                <br />
                　体験レッスンの費用：{' '}
                <Badge px={2} py={1} mb={1}>
                  {(
                    TRIAL_PRICE *
                      (1 - (Number(currentUser?.trial_discount) || 0) / 100) +
                    (currentUser?.sex === 'male'
                      ? Number(currentUser?.trial_discount) === 100
                        ? 0
                        : 1000
                      : 0)
                  )?.toLocaleString()}
                </Badge>{' '}
                円{currentUser.trialPaid ? '（支払済み）' : ''}
                <br />
                　入会金＋事務手数料：{' '}
                <Badge px={2} py={1} mb={1}>
                  {(
                    INITIAL_PRICE *
                    (1 - currentUser?.initial_discount / 100)
                  )?.toLocaleString()}
                </Badge>{' '}
                円
              </Text>
              <Flex justifyContent="center" mt={4}>
                <Button
                  size="sm"
                  onClick={onClickInitialPayment}
                  variant={
                    currentUser.initial === true
                      ? 'outlineBlack'
                      : 'stylishBlack'
                  }
                >
                  {currentUser?.initial
                    ? 'お支払い済み'
                    : currentUser.trialPaid
                    ? 'スキップ'
                    : 'お会計へ'}
                </Button>
              </Flex>
            </Box>
            <Text fontSize="xs" textAlign={'center'}>
              {currentUser?.initial
                ? '▼'
                : '初期費用のお会計が完了する８０％完了です🙆‍♀️'}
            </Text>
            {currentUser && currentUser?.initial && (
              <>
                {/* <Alert status="warning" my="8px">
            <AlertIcon />
            <Box flex="1">
              <AlertTitle fontSize={14}>曜日と時間の設定がまだです！</AlertTitle>
              <AlertDescription fontSize={12} display="block">
                公式LINEにてインストラクターと希望の曜日と時間を調整してください。
              </AlertDescription>
            </Box>
          </Alert> */}
                {
                  <Box
                    borderWidth="1px"
                    borderRadius="xl"
                    overflow="hidden"
                    p={4}
                    mt={2}
                    bg={localStorage.plan ? 'gray.100' : 'white'}
                  >
                    <Text fontSize={12}>
                      <Badge
                        px={2}
                        py={1}
                        backgroundColor={'orange.300'}
                        color={'white'}
                        mb={2}
                      >
                        2. プランの設定
                      </Badge>
                    </Text>
                    <form
                      onSubmit={e => e.preventDefault()}
                      style={{ width: '100%' }}
                    >
                      <Flex
                        flexDirection="column"
                        alignItems="start"
                        width="100%"
                      >
                        <FormLabel fontSize="12" mb="1">
                          {PLAN_DESCRIPTION[selectedPlan]}
                        </FormLabel>
                        <Select
                          value={selectedPlan}
                          onChange={e => setSelectedPlan(e.target.value)}
                          width="100%"
                        >
                          {Object.keys(PLAN_MENU)?.map(plan => (
                            <option key={plan} value={plan}>
                              {PLAN_MENU[plan]}
                            </option>
                          ))}
                        </Select>
                        <Flex width="100%" justifyContent="center">
                          <Button
                            size="sm"
                            onClick={() => setPlanNumber()}
                            variant={
                              localStorage.plan
                                ? 'outlineBlack'
                                : 'stylishBlack'
                            }
                            mt={2}
                          >
                            {localStorage.plan ? '再設定する' : 'プランを設定'}
                          </Button>
                        </Flex>
                        {localStorage.plan ? (
                          <Text fontSize={10} mt={2} alignSelf={'center'}>
                            選択し直して再設定するを押すと変更できます。
                          </Text>
                        ) : null}
                      </Flex>
                      {/* {selectedPlan === 4 || selectedPlan === 6 ? renderTimeSelectionForm(selectedDay, setSelectedDay, selectedTime, setSelectedTime, processedOpeningTimes) : null}
              {selectedPlan === 8 ? (
                <>
                  {renderTimeSelectionForm(selectedDay, setSelectedDay, selectedTime, setSelectedTime, processedOpeningTimes)}
                  {renderTimeSelectionForm(selectedDay2, setSelectedDay2, selectedTime2, setSelectedTime2, processedOpeningTimes)}
                </>
              ) : null} */}
                    </form>
                  </Box>
                }
                <Text fontSize="xs" textAlign={'center'} mt={2}>
                  {localStorage.plan
                    ? '▼'
                    : 'プランを設定すると９０％完了です🙆‍♀️'}
                </Text>
              </>
            )}
            {currentUser && localStorage.plan && currentUser.initial && (
              <>
                <Box
                  borderWidth="1px"
                  borderRadius="xl"
                  overflow="hidden"
                  p={4}
                  mt={2}
                  bg={currentUser.initial === false ? 'gray.100' : 'white'}
                >
                  <Text fontSize={12}>
                    <Badge
                      px={2}
                      py={1}
                      backgroundColor={'gray.600'}
                      color={'white'}
                      mb={2}
                    >
                      3. 利用カードの登録（{planProduct.name || 'なし'}）
                    </Badge>
                    <br />
                    　月額費用：{' '}
                    <Badge px={2} py={1} mb={1}>
                      {planPrice?.unit_amount?.toLocaleString() || '0'}
                    </Badge>{' '}
                    円
                  </Text>
                  <Flex justifyContent="center" mt={4}>
                    <Button
                      size="sm"
                      onClick={onClickRegisterPlan}
                      variant={
                        currentUser.initial === false
                          ? 'outlineBlack'
                          : 'stylishBlack'
                      }
                    >
                      ご登録へ
                    </Button>
                  </Flex>
                  {/* <Flex justifyContent="center" mt={2}>
                    <Text fontSize={10} color="red" fontWeight={'bold'}>
                      ※ 月額プラン登録に「ApplePay」は利用できません
                    </Text>
                  </Flex> */}
                </Box>

                {/* <Flex justifyContent="center" mt={2}>
                  <Text fontSize={10} color="red" px={6}>
                    ※
                    プラン登録にApplePayを利用してしまった人は先に進めなくなります。公式LINEにご連絡ください。
                  </Text>
                </Flex> */}
              </>
            )}
          </Box>
        </Container>
      )}
      {'　'}
    </>
  );
};

export default Before;
