import React from 'react';
import { Route, Navigate } from 'react-router-dom';
import {
  LoginPage,
  SignUpPage,
  Manage,
  // AddReservation,
  PriorityReservation,
  Tasks,
  Studio,
  StudioList,
  FormList,
  Payments,
  Home,
  Before,
  AddStudioPage,
  Reservations,
  Profile,
  ChangeReservation,
  ChangePlan,
  Suspend,
  Terminate,
  InitialPaymentSuccess,
  TrialPaymentSuccess,
  TrialConfirm,
  PlanSubscriptionSuccess,
  OncePaymentSuccess,
  PlanTicketPaymentSuccess,
  PrivateRoute,
  SetStatusTrue,
  User,
  SetPlan,
  Maintenance,
  SetFixies,
  TermsOfService,
  Confirm,
  Trial,
  Reserve,
  TrialReservation,
  Shift,
  SetShift,
  Notifications,
  AddReservation2,
  Block,
  Reports,
  Waiting,
  Tickets,
  AllowTerminate,
  PasswordRessetPage,
  StudioChangePage,
} from './components';

// パブリックルートの設定
const publicRoutes = [
  { path: '/login', component: <LoginPage /> },
  { path: '/resset', component: <PasswordRessetPage /> },
  { path: '/signup', component: <SignUpPage /> },
  { path: '/confirm', component: <Confirm /> },
  { path: '/trial', component: <Trial /> },
  { path: '/trial-confirm', component: <TrialConfirm /> },
  { path: '/trial-reservation', component: <TrialReservation /> },
  { path: '/terms-of-service', component: <TermsOfService /> },
  // その他のパブリックルートを追加
];

// プライベートルートの設定
const privateRoutes = [
  { path: '/manage', component: Manage },
  { path: '/maintenance', component: Maintenance },
  { path: '/add-reservation', component: AddReservation2 },
  { path: '/priority-reservation', component: PriorityReservation },
  { path: '/tasks', component: Tasks },
  { path: '/studios/:studioId', component: Studio },
  { path: '/studios/:studioId/shift', component: Shift },
  { path: '/studios/:studioId/users/:userId', component: User },
  { path: '/studios/:studioId/instructors/:userId/shift', component: SetShift },
  {
    path: '/studios/:studioId/instructors/:userId/block',
    component: Block,
  },
  {
    path: '/studios/:studioId/users/:userId/reservations',
    component: Reservations,
  },
  {
    path: '/studios/:studioId/users/:userId/tickets',
    component: Tickets,
  },
  {
    path: '/studios/:studioId/users/:userId/allow-terminate',
    component: AllowTerminate,
  },
  {
    path: '/studios/:studioId/users/:userId/notifications',
    component: Notifications,
  },
  { path: '/studios', component: StudioList },
  { path: '/forms', component: FormList },
  { path: '/payments', component: Payments },
  { path: '/', component: Home, title: 'Home' },
  { path: '/home', component: Home, title: 'Home' },
  { path: '/before', component: Before },
  { path: '/add-studio', component: AddStudioPage },
  { path: '/profile', component: Profile },
  { path: '/change-reservation', component: ChangeReservation },
  { path: '/change-studio', component: StudioChangePage },
  { path: '/change-plan', component: ChangePlan },
  { path: '/suspend', component: Suspend },
  { path: '/terminate', component: Terminate },
  { path: '/initial-payment-success', component: InitialPaymentSuccess },
  { path: '/trial-payment-success', component: TrialPaymentSuccess },
  { path: '/plan-subscription-success', component: PlanSubscriptionSuccess },
  { path: '/once-payment-success', component: OncePaymentSuccess },
  { path: '/plan-ticket-payment-success', component: PlanTicketPaymentSuccess },
  { path: '/set-status-true', component: SetStatusTrue },
  { path: '/set-plan', component: SetPlan },
  { path: '/set-fixies', component: SetFixies },
  { path: '/reports', component: Reports },
  { path: '/waiting', component: Waiting },
  { path: '/reserve', component: Reserve },
  // その他のプライベートルートを追加
];

// 認証状態に基づいてルートを生成する関数
export const routing = isAuthenticated =>
  [
    ...publicRoutes,
    ...privateRoutes.map(({ path, component, title }) => ({
      path,
      component: isAuthenticated ? (
        <PrivateRoute title={title}>
          {React.createElement(component)}
        </PrivateRoute>
      ) : (
        <Navigate replace to="/login" />
      ),
    })),
  ].map(({ path, component }) => (
    <Route key={path} path={path} element={component} />
  ));
